import { urls } from '../constants/app';
import request from './utils';
import { setError, resetError } from './errors';
import moment from 'moment';

export function getCurrentUser() {
    return (dispatch, getState) => {
        const user = getState().user.info;
        if (user) {
            dispatch({ type: 'SET_LOADER', field: 'global', value: false });
        } else {
            dispatch({ type: 'SET_LOADER', field: 'global', value: true });
            request({ method: 'get', url: urls.currentUser })
                .then((response) => {
                    dispatch({ type: 'SET_USER_INFO', user: response });
                    dispatch({ type: 'SET_LOADER', field: 'global', value: false });
                })
                .catch(error => {
                    dispatch(setError(error, 'global'));
                    dispatch({ type: 'SET_LOADER', field: 'global', value: false });
                });
        }
    }
}

export function fetchUsers() {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'users', value: true });
    const user = getState().user.info;
    const paging = getState().users.paging;
    const sorting = getState().users.sorting;
    const filters = getState().users.filters;

    const notParams = { 'id_ne': user.id };
    const params = {};
    if (user.role.id === 3) {
        notParams['role.id_nin'] = [3,4];
    }

    if (filters.city) {
        notParams['city.id'] = filters.city.value;
    }

    if (filters.phone) {
        notParams['phone'] = filters.phone;
    }

    if (filters.name) {
        notParams['name'] = filters.name;
    }

    if (filters.point) {
        notParams['points.id_in'] = filters.point.value;
    }

    if (filters.createdStartDate) {
        notParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    if (filters.createdEndDate) {
        notParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;

    params['_sort'] = `${sorting.field}:${sorting.type}`;

    request({ method: 'get', url: urls.counter+'/users', params: notParams })
        .then((response) => {
            dispatch({ type: 'SET_USERS_COUNT', count: response ? response : 0 });
            return;
        })
        .then(() => {
            return request({ method: 'get', url: urls.users, params: { ...params, ...notParams } }).then(res => {
                dispatch({ type: 'SET_USERS', users: res });
                dispatch({ type: 'SET_LOADER', field: 'users', value: false });
            });
        })
        .catch(error => {
            dispatch(setError(error, 'users'));
            dispatch({ type: 'SET_LOADER', field: 'users', value: false });
        });
    }
}

export function setUsersPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_USERS_PAGING', paging });
    }
}

export function setUsersFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_USERS_FILTERS', filters });
    }
}

export function fetchUser(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'user', value: true });

    return request({ method: 'get', url: `${urls.users}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_USER', user: response });
            dispatch({ type: 'SET_LOADER', field: 'user', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'user'));
            dispatch({ type: 'SET_LOADER', field: 'user', value: false });
            return null
        });
    }
}

export function updateUser(id, params) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'user', value: true });
    dispatch(resetError('user'));
    return request({ method: 'put', url: `${urls.users}/${id}`, data: params })
        .then((response) => {
            dispatch({ type: 'SET_USER', user: response });
            dispatch({ type: 'SET_LOADER', field: 'user', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 506 }, 'user'));
            dispatch({ type: 'SET_LOADER', field: 'user', value: false });
            return false;
        });
    }
}

export function deleteUser(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'users', value: true });
    dispatch(resetError('users'));
    request({ method: 'delete', url: `${urls.users}/${id}` })
        .then((response) => {
            dispatch(fetchUsers());
            dispatch({ type: 'SET_LOADER', field: 'users', value: false });
        })
        .catch(error => {
            dispatch(setError({ status: 507 }, 'users'));
            dispatch({ type: 'SET_LOADER', field: 'users', value: false });
        });
    }
}

export function registerUser(user) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'register', value: true });
    dispatch(resetError('register'));
    return request({ method: 'post', url: urls.users, data: user })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'register', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 506 }, 'register'));
            dispatch({ type: 'SET_LOADER', field: 'register', value: false });
            return false;
        });
    }
}

export function setAddForm(data) {
    return (dispatch) => {
        dispatch({ type: 'SET_ADD', data });
    }
}

export function sendCashBack(cashback, userID, orderID, operationType) {
    return request({ method: 'post', url: `${urls.cashback}/send`, data: { cashback, userID, orderID, operationType } })
        .then((response) => {
            return true;
        })
        .catch(error => {
            return false;
        });
}