import React from 'react';
import { Link, } from 'react-router-dom';
import Content from '../../components/layout/Content';
import BackButton from '../../components/utils/BackButton';

export default function EditGoodPage() {
    const renderSubHeader = () => {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <BackButton />
                    <h3 className="kt-subheader__title">Грибная</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            Пицца
                        </span>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    <Link className="btn btn-danger btn-icon-sm" to="/good/add">Удалить</Link>
                    <button className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                </div>
            </div>
            </div>
        );
    };
    return (
        <Content>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                Content
            </div>
        </Content>
    );
}