import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { utils, read } from 'xlsx';
import { fromStore } from '../../selectors';
import { fetchChanels, setNewMessageForm, addMessage, resetNewMessageForm, fetchMessages } from '../../action/messages';
import swal from 'sweetalert';

const ImportMessage = () => {
    const dispatch = useDispatch();
    const refImport = useRef();
    const newMessage = useSelector(fromStore.messagesNewMessageSelector);
    const addLoader = useSelector(fromStore.loaderMessagesSelector);

    useEffect(() => {
        dispatch(fetchChanels());

        return () => {
            dispatch(resetNewMessageForm());
        }
    }, [dispatch]);    

    const onChangeNewMessage = (field) => (value) => {
        dispatch(setNewMessageForm(field, value));
    }

    const onRefresh = () => {
        dispatch(resetNewMessageForm());
    };

    const isValidMessage = () => {
        const { theme, message } = newMessage;

        if (!theme || !message) {
            return false;
        }
        return true;
    }

    const onImport = async (e) => {
        if (!e.target.files[0]) return;

        const file = e.target.files[0];

        if (!file.arrayBuffer) return;

        const data = await file.arrayBuffer();

        if (!data) return;

        if(data) {
            const wb = await read(data);
            const ws = wb.Sheets[wb.SheetNames[0]];
            const pricesExcel = utils.sheet_to_json(ws);
            const result = pricesExcel.map(item => {
                const price = {
                    phone: item['Телефон']
                }
                return price;
            });
            console.log('result', result);
            debugger;
            
            const { theme, message } = newMessage;

            let updatedChanels = [{ key: "specificUsers", phones: result }];
            
            const dataMessage = {
                theme,
                message,
                chanels: updatedChanels
            };
            await dispatch(addMessage(dataMessage)).then(result => {
                debugger;
                if (result === 'success') {
                    swal({
                        title: "Успех",
                        text: "Сообщение отправлено",
                        icon: "success",
                        confirmButtonText: 'OK'
                    });
                    
                    fetchMessages('sended', { status: 'sended' });
                    onRefresh();
                } else {
                    swal({
                        title: "Ошибка",
                        text: "Произошла ошибка",
                        icon: "warning",
                        dangerMode: true,
                        confirmButtonText: 'OK'
                    });

                    onRefresh();
                }
            });
        }

        if(refImport && refImport.current) {
            refImport.current.value = "";
        }
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid    kt-portlet    kt-inbox__list kt-inbox__list--shown" style={{ display: 'flex' }}>
            <div className="kt-portlet__head">
                <div className="kt-inbox__toolbar kt-inbox__toolbar--extended">
                    <div className="kt-inbox__actions kt-inbox__actions--expanded">
                        <div className="kt-inbox__check">
                        <h5 style={{ marginBottom: 0 }}>Новое уведомление</h5>
                        </div>
                    </div>
                    <div className="kt-inbox__controls">
                        <label className="btn btn-dark btn-icon-sm">
                            <input type="file" onChange={onImport} ref={refImport} hidden 
                            disabled={!isValidMessage()}
                            />
                            Импортировать и отправить
                        </label>
                    </div>
                </div>
            </div>
            
            <div className="kt-portlet__body kt-portlet__body--fit">
                <div className="kt-notification">
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title">
                                    <input disabled={addLoader} value={newMessage.theme} onChange={(e) => onChangeNewMessage('theme')(e.target.value)} className="form-control" placeholder="Заголовок уведомления"/>
                                </div>
                            </div>
                        </div>
                        <div className="kt-notification__item">
                            <div className="kt-notification__item-details">
                                <div className="kt-notification__item-title">
                                    <div className="kt-inbox__editor ql-container ql-snow" id="kt_inbox_compose_editor">
                                        <textarea disabled={addLoader} value={newMessage.message} onChange={(e) => onChangeNewMessage('message')(e.target.value)} className="customTextArea" placeholder="Текст сообщения"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default ImportMessage;
