const initialState = {
    cities: [],
    filials: [],
    editFilials: [],
    zones: [],
    add: {},
    paging: {
      city: {
        start: 0,
        limit: 10
      },
      filial: {
        start: 0,
        limit: 10
      }
    },
    sorting: {
      city: {
        field: 'name',
        type: 'asc'
      },
      filial: {
        field: 'city',
        type: 'desc'
      }
    },
    count: {
      cities: 0,
      filials: 0
    },
    filters: {
        filial: {
            city: null
        }
    },
    city: {
      detail: {},
      edit: null
    },
    filial: {
      detail: {},
      edit: null,
    },
    zone: {
      detail: {},
      edit: null,
    }
};

export default function handbook(state = initialState, action) {
  switch(action.type) {
    case 'SET_CITIES': {
          return {
              ...state,
              cities: action.cities
          };
    }
    case 'SET_CITY': {
      return {
        ...state,
        city: {
          ...state.city,
          detail: action.city
        }
      }
    }
    case 'SET_ZONES': {
      return {
        ...state,
        zones: action.zones
      }
    }
    case 'SET_FILIAL': {
      return {
        ...state,
        filial: {
          ...state.filial,
          detail: action.filial
        }
      }
    }
    case 'SET_EDIT_FILIAL': {
      return {
        ...state,
        filial: {
          ...state.filial,
          edit: action.data
        }
      }
    }
    case 'SET_ZONE': {
      return {
        ...state,
        zone: {
          ...state.zone,
          detail: action.zone
        }
      }
    }
    case 'SET_EDIT_ZONE': {
      return {
        ...state,
        zone: {
          ...state.zone,
          edit: action.data
        }
      }
    }
    case 'SET_EDIT_CITY': {
      return {
        ...state,
        city: {
          ...state.city,
          edit: action.data
        }
      }
    }
    
    case 'SET_HANDBOOK_PAGING': {
      return {
          ...state,
          paging: {
            ...state.paging,
            [action.field]: action.paging
          }
      };
    }

    case 'SET_HANDBOOK_FILTERS': {
      return {
          ...state,
          filters: {
            ...state.filters,
            [action.field]: action.filters
          }
      };
    }

    case 'SET_HANDBOOK_COUNT': {
      return {
          ...state,
          count: {
            ...state.count,
            [action.field]: action.count
          }
      };
    }
    case 'SET_FILIALS': {
      return {
          ...state,
          filials: action.filials
      };
    }
    case 'CHANGE_EDIT_FILIALS': {
      return {
          ...state,
          editFilials: state.editFilials.map(
            editFilial => {
              if (editFilial.id === action.data.id) {
                return action.data;
              }
              return editFilial;
            }
          )
      };
    }
    case 'ADD_EDIT_FILIALS': {
      return {
          ...state,
          editFilials: [...state.editFilials, action.data]
      };
    }
    case 'REMOVE_EDIT_FILIALS': {
      return {
         ...state,
         editFilials: state.editFilials.filter((editFilial) => editFilial.id !== action.data.id)
      };
    }
    case 'SET_ADD_HANDBOOK': {
      return {
          ...state,
          add: action.data
      };
    }
    default: return state;
  }
}