import { urls, baseUrl } from '../constants/app';
import moment from 'moment';
import request from './utils';
import axios from 'axios';
import { setError, resetError } from './errors';
axios.defaults.baseURL = baseUrl;

export function fetchGoods() {
    return (dispatch, getState) => {
    const goods = getState().goods.list;
    if (goods.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'goods', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/items')
            .then((response) => {
                dispatch({ type: 'SET_GOODS', goods: response.data });
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return null
            });
        }
    }
}

export function fetchCategories() {
    return (dispatch, getState) => {
    const categories = getState().goods.categories;
    if (categories.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'categories', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/category')
            .then((response) => {
                dispatch({ type: 'SET_CATEGORIES', categories: response.data });
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return null
            });
        }
    }
}

export function fetchOrders(dataParams = {}, isArchive) {
    return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
    const paging = getState().orders.paging;
    const sorting = getState().orders.sorting;
    const user = getState().user.info;
    const filters = getState().orders.filters;

    if(!filters.createdEndDate)    
        filters.createdEndDate = new Date().setHours(23, 59, 59, 999)
    
    if(!filters.createdStartDate)
        filters.createdStartDate = new Date().setHours(0, 0, 0, 0)

    const params = {
        ...dataParams
    };

    if (user.city) {
        params.city = user.city.id ;
    }

    if (filters.city) {
        dataParams['city.id'] = filters.city.value;
        params['city.id'] = filters.city.value;
    }

    if (filters.zone) {
        dataParams['zone_null'] = false;
        params['zone_null'] = false;
    }

    if (filters.phone) {
        dataParams['phone'] = filters.phone;
        params['phone'] = filters.phone;
    }

    if (filters.name) {
        dataParams['userName'] = filters.name;
        params['userName'] = filters.name;
    }

    if (filters.point) {
        dataParams['point.id'] = filters.point.value;
        params['point.id'] = filters.point.value;
    }

    if (filters.status) {
        dataParams['status'] = filters.status.value;
        params['status'] = filters.status.value;
    }

    if (filters.createdStartDate) {
        dataParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    if (filters.createdEndDate) {
        dataParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
    }

    params['_start'] = paging.start;
    params['_limit'] = paging.limit;
    params['_sort'] = `${sorting.field}:${sorting.type}`;

    const url = isArchive ? urls.archive : urls.orders;

    request({ method: 'get', url: url+'/count', params: dataParams })
        .then(result => {
            dispatch({ type: 'SET_ORDERS_COUNT', count: result });
            return;
        })
        .then(() => {
            return request({ method: 'get', url, params }).then((response) => {
                dispatch({ type: 'SET_ORDERS', orders: response });
                dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            })
        })
        .catch(error => {
            dispatch(setError(error, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
        });
    }
}

export function deleteOrder(id, isArchive) {
    return (dispatch) => {
    const url = isArchive ? urls.archive : urls.orders;
    dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
    dispatch(resetError('orders'));
    return request({ method: 'delete', url:` ${url}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 507 }, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return false;
        });
    }
}

export function setOrdersFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_FILTERS', filters });
    }
}

export function updateOrder(id, data, isArchive) {
    return (dispatch) => {
    const url = isArchive ? urls.archive : urls.orders;
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });
    dispatch(resetError('orders'));

        return request({ method: 'put', url: `${url}/${id}`, data })
            .then((response) => {
                dispatch({ type: 'SET_ORDER', order: response });
                dispatch({ type: 'SET_LOADER', field: 'order', value: false });
                return true;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'orders'));
                dispatch({ type: 'SET_LOADER', field: 'order', value: false });
                return false;
            });
    
    }
}

export function updateOrderFromTable(id, data, isArchive) {
    return (dispatch) => {
    const url = isArchive ? urls.archive : urls.orders;
    dispatch(resetError('orders'));

        return request({ method: 'put', url: `${url}/${id}`, data })
            .then(() => {
                dispatch({ type: 'UPDATE_ORDER_POINT', id: id, point: data.point, zone: data.zone, city: data.city, status: data.status });
                return true;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'orders'));
                return false;
            });
    }
}

export function fetchOrder(id, isArchive) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });

    const url = isArchive ? urls.archive : urls.orders;

    return request({ method: 'get', url: `${url}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_ORDER', order: response });
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'order'));
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return null
        });
    }
}

export function setOrdersPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_PAGING', paging });
    }
}