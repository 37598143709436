import React from 'react';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import swal from 'sweetalert';
import { getImageUrl } from '../utils/common';
import ActionMenu from '../components/utils/ActionMenu';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../selectors';
import { deleteNews } from '../action';
import { useDispatch } from 'react-redux';

function NewsList({ items, history }) {
    const role = useSelector(userRoleSelector);
    const dispatch = useDispatch();

    const renderThead = () => {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell" width="100px">
                        <span>Картинка</span>
                    </th>
                    <th className="kt-datatable__cell" width="300px">
                        <span>Заголовок</span>
                    </th>
                    <th className="kt-datatable__cell" width="110px">
                        <span>Дата</span>
                    </th>
                    <th className="kt-datatable__cell" width="300px">
                        <span>Краткое описание</span>
                    </th>
                    <th className="kt-datatable__cell text-left" width="auto">
                        <span>Полное описание</span>
                    </th>
                    <th className="kt-datatable__cell" width="80px">
                        <span>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }

    const onDelete = (id) => {
        swal({
            title: `Удаление новости`,
            text: `Вы действительно хотите удалить новость #${id}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteNews(id));
            }
        });
    }

    const handleDoubleClick = (item) => () => {
        history.push(`/news/${item.id}`)
    };

    const renderBody = () => {
        return (
            <tbody className="kt-datatable__body">
                {
                   items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Новостей пока нет</td>
                        </tr>
                    )
                }
                {
                    items.map(item => {
                        const actions = [
                            { access: 4, name: 'Редактирование', icon: 'flaticon2-contract', action: () => history.push(`/news/${item.id}`) },
                            { access: 4, name: 'Удалить', icon: 'flaticon2-trash', action: () => onDelete(item.id) }
                        ].filter(item => !item.access || item.access === role);

                        return (
                        <tr onDoubleClick={handleDoubleClick(item)} key={item.id} className="kt-datatable__row baseline-row">
                            <td className="kt-datatable__cell" width="100px">
                                <img width="100%" src={getImageUrl(item.image)} alt="231"/>
                            </td>
                            <td className="kt-datatable__cell" width="300px">
                                {item.title}
                            </td>
                            <td className="kt-datatable__cell" width="110px">
                                {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                            </td>

                            <td className="kt-datatable__cell" width="300px">
                                {item.description}
                            </td>
                            <td className="kt-datatable__cell" width="auto">
                                {item.text}
                            </td>
                            <td className="kt-datatable__cell" align='right' width="80px">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    return (
        <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
            {renderThead()}
            {renderBody()}
        </table>
    );

}

export default withRouter(NewsList);