import React, { Fragment, useState } from 'react';
import Content from '../../components/layout/Content';
import ErrorContainer from '../../components/utils/ErrorContainer';
import { BlockedList } from '../../containers/blockedIp';
import { CodesList } from '../../containers/phonecodes';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';

const BlockedIP = () => {
    const countIps = useSelector(fromStore.countIpsSelector); 
    const [mode, setMode] = useState('blocked');

    const onChangeMode = (mode) => {
        setMode(mode);
    };

    const renderSubHeader = (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">Блокировка IP</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group">
                            <span className="kt-subheader__desc">
                                
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    );

    const renderContentComponent = () => {
        switch (mode) {
            case 'blocked': return <BlockedList />;
            case 'noactive': return <CodesList />;
            default: return null;
        }
    }

    const onClickMenuItem = (mode) => (e) => {
        e.preventDefault();
        onChangeMode(mode);
    }

    const renderContent = (
        <Fragment>
            <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop  kt-inbox" id="kt_inbox">
                <div className="kt-grid__item   kt-portlet  kt-inbox__aside" id="kt_inbox_aside" style={{ opacity: 1, paddingTop: 0, marginRight: '25px' }}>
                    <div className="kt-inbox__nav">
                        <ul className="kt-nav">
                            <li className={cn({ 'kt-nav__item': true, 'kt-nav__item--active': mode === 'blocked' })}>
                                <a onClick={onClickMenuItem('blocked')} href="/" className="kt-nav__link" data-action="list" data-type="inbox">
                                    <span className="kt-nav__link-text">Заблокированные IP ({countIps})</span>
                                </a>
                            </li>
                            
                            <li className={cn({ 'kt-nav__item': true, 'kt-margin-b-20': true, 'kt-nav__item--active': mode === 'noactive' })}>
                                <a href="/" onClick={onClickMenuItem('noactive')} className="kt-nav__link" data-action="list" data-type="trash">
                                    <span className="kt-nav__link-text">Неподтвержденные коды</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                {renderContentComponent()}
            </div>
        </Fragment>
    );

        
    return (
        <Fragment>
            { renderSubHeader }
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <Content>
                <ErrorContainer field="ips" container={true} hasCloseButton={true}/>
                <ErrorContainer field="phonecodes" container={true} hasCloseButton={true}/>
                {renderContent}
            </Content>
            </div>
        </Fragment>
    );
}

export default BlockedIP;