import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import UserInfo from '../user/UserInfo';
import { withRouter, Link } from 'react-router-dom';
import cn from 'classnames'

class Header extends Component {
    
    render() {
        return (
            <div className="kt-header kt-grid__item  kt-header--fixed ">
              <button
                className="kt-header-menu-wrapper-close"
                id="kt_header_menu_mobile_close_btn">
                <i className="la la-close"></i>
              </button>

              <div className="kt-header-menu-wrapper">
                <div className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                  <ul className="kt-menu__nav ">
                    <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.match.path === '/orders' })}>
                      <Link to="/orders" className="kt-menu__link">
                        <span className="kt-menu__link-text">Заказы</span>
                      </Link>
                    </li>
                    {
                      this.props.user && this.props.user.role.id === 4 && (
                          <Fragment>
                            <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.location.pathname === '/statistic' })}>
                                <Link to="/statistic" className="kt-menu__link kt-menu__toggle">
                                  <span className="kt-menu__link-text">Статистика</span>
                                </Link>
                            </li>
                            <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.location.pathname === '/handbook/cities/add' })}>
                                <Link to="/handbook/cities/add" className="kt-menu__link kt-menu__toggle">
                                  <span className="kt-menu__link-text">Добавить город</span>
                                </Link>
                            </li>
                            <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.location.pathname === '/handbook/filials/add' })}>
                                <Link to="/handbook/filials/add" className="kt-menu__link kt-menu__toggle">
                                  <span className="kt-menu__link-text">Добавить филиал</span>
                                </Link>
                            </li>
                            <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.location.pathname === '/handbook/zone/add' })}>
                                <Link to="/handbook/zone/add" className="kt-menu__link kt-menu__toggle">
                                  <span className="kt-menu__link-text">Добавить зону</span>
                                </Link>
                            </li>
                            <li className={cn({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.location.pathname === '/users/addManager' })}>
                                <Link to="/users/addManager" className="kt-menu__link kt-menu__toggle">
                                    <span className="kt-menu__link-text">Добавить менеджера</span>
                                </Link>
                            </li>
                          </Fragment>
                      )
                    }
                    
                    </ul>
                </div>
              </div>

              <div className="kt-header__topbar">
                <UserInfo />
              </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user.info
});

export default withRouter(connect(mapStateToProps)(Header));