import React, { useState, useEffect } from 'react';
import { withRouter  } from 'react-router-dom';
import Input from '../../components/utils/Input';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';
import 'react-markdown-editor-lite/lib/index.css';
import { useDispatch } from 'react-redux';
import { fetchDocumentationDetail, updateDocumentation } from '../../action';
import Loader from '../../components/utils/Loader';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

function EditDocumentation({ history, match }) {
    const dispatch = useDispatch();
    const id = match.params.id;
    const loadingSave = useSelector(fromStore.loaderAddDocumentationSelector);
    const loader = useSelector(fromStore.loaderDocumentationSelector);
    const detail = useSelector(fromStore.documentationDetailSelector);
    const [addData, setAddData] = useState({});

    useEffect(() => {
        dispatch(fetchDocumentationDetail(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (detail) {
            setAddData({
                title: detail.title,
                description: detail.description,
                text: detail.text,
            });
        }
    }, [detail]);

    if (loader || !detail) {
        return (
            <div style={styles.loading}><Loader /></div>
        );
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">{detail.title}</h3>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => history.push('/documentation')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    const isValidDocumentation = () => {
        const { title, description, file } = addData;
        if (title || description || file) return true;
        
        return false;
    }

    const setData = (field) => (e) => {
        const data = {...addData};
        data[field] = e.target.value;
        setAddData(data);
    }

    const setFile = (e) => {
       const data = {...addData};
       data.file = e.target.files[0];
       data.oldFileId = e.target.files[0];
       setAddData(data);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const rq = { ...addData };
        if (rq.file) {
            rq.fileData = detail.file;
        }
        const result = dispatch(updateDocumentation(id, rq));
        if (result) {
            history.push('/documentation'); // un commented
        }
    }

    return (
        <div className='kt-content kt-subheader--enabled kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor'>
            { renderSubHeader() }
            <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                
            <div className="kt-portlet">
                <form onSubmit={onSubmit} className="kt-form">
                    <div className="kt-portlet__body">
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Заголовок"
                                    required={true}
                                    onChange={setData('title')}
                                    value={addData.title}
                                    placeholder="Заголовок..."
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '25px' }}>
                            <div className="col-md-12">
                                <label>Файл *</label>
                                <div>
                                    <input type="file" onChange={setFile}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Краткое описание"
                                    required={true}
                                    onChange={setData('description')}
                                    value={addData.description}
                                    placeholder="Краткое описание..."
                                />
                            </div>
                        </div>
                    </div>
                    <ErrorContainer field="addDocumentation" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                    <div className="kt-portlet__foot">
                        <div className="kt-form__actions">
                            <button
                                disabled={!isValidDocumentation() || loadingSave}
                                type="submit"
                                className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loadingSave })}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default withRouter(EditDocumentation);